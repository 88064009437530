import * as React from "react"

const featureData = [
  {
    id: 1,
    title: 'Learn faster',
    category: 'For riders',
    imageUrl:
      'https://imagedelivery.net/cWhxz9lvedLRJjTRKti8ew/c75f6d36-e416-4751-4f81-8e9bd6e0c200/public',
    text:
      'Find a new trainer, find a new horse, or find a new barn.',
  },
  {
    id: 2,
    title: 'Reach more students',
    category: 'For trainers',
    imageUrl:
      'https://imagedelivery.net/cWhxz9lvedLRJjTRKti8ew/723ead00-88b0-47be-251f-56760d3f8b00/marketing1',
    text:
      'Record your lessons, send notes, and get paid on time.',
  },
  {
    id: 3,
    title: 'Eat more alfalfa',
    category: 'For horses',
    imageUrl:
      'https://imagedelivery.net/cWhxz9lvedLRJjTRKti8ew/a30c2b26-1c17-423a-f112-606696ed8d00/public',
    text:
      `Schedule a farrier, track medications, and keep tabs on meals.`
  },
]

const FeatureTriplet = () => (
    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
      {featureData.map((feature) => (
          <div key={feature.id} className="flex flex-col items-center overflow-hidden">
            <div className="flex-shrink-0">
              <img src={feature.imageUrl} className="h-48 w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 object-cover"/>
            </div>
            <div className="flex-1 p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-cyan-600">
                  {feature.category}
                </p>
                <div className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">{feature.title}</p>
                  <p className="mt-3 text-base text-gray-500">{feature.text}</p>
                </div>
              </div>
            </div>
          </div>
      ))}
    </div>
)

export default FeatureTriplet

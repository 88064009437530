import {
  FilmIcon,
  SparklesIcon
} from '@heroicons/react/outline'
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import FeatureTriplet from "../components/feature-triplet"
import FooterLight from "../components/footer-light"
import NavbarDark from "../components/navbar-dark"
import Seo from "../components/seo"

const IndexPage = () => (
  <div className="bg-white">
    <Seo title="HorseOfCourse" />
    <div className="relative overflow-hidden">
      <NavbarDark />
      <main>
        <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <p className="pb-3 sm:pb-5">
                      Your{' '}
                      <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400">horse&apos;s</span>{' '} favorite app, of course.
                    </p>
                  </h1>
                  <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                    Join a growing community of riders and trainers.
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <form action="https://api-mkt.horseofcourseapp.com/join-waitlist" method="POST" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div className="sm:flex">
                        <div className="min-w-0 flex-1">
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            placeholder="Enter your email"
                            className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                          />
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <button
                            type="submit"
                            className="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                          >
                            Join the waitlist
                          </button>
                        </div>
                      </div>
                      <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                        We&apos;re admitting new users slowly to keep the community safe and welcoming. We&apos;ll notify you as soon as a slot opens up.
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <StaticImage
                    src="../images/xties.jpg"
                    loading="eager"
                    quality={90}
                    formats={["auto", "webp", "avif"]}
                    alt="horse in crossties"
                    imgClassName="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none shadow-2xl rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-gray-100 py-8 sm:py-16 lg:py-24">
          <div className="relative">
            <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">The Equestrian lifestyle</h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Something for everyone
              </p>
              <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                Riders, trainers, horses, partners and supporters, parents or grandparents &mdash; anyone in the equestrian community can join.
              </p>
            </div>
            <FeatureTriplet />
          </div>
        </div>

        <div className="relative pt-16 pb-32 bg-gray-50 overflow-hidden">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-teal-500 to-cyan-600">
                      <FilmIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Get personalized instruction
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      It&apos;s hard to remember everything your trainer shouts at you during a lesson. Ask for online help so you can work on improving between sessions.
                    </p>
                  </div>
                </div>
                <div className="mt-8 pt-6">
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <StaticImage
                    src="../images/video-review.png"
                    quality={100}
                    format={["auto", "webp", "avif"]}
                    alt="trainer critique of rider video"
                    className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                    imgClassName="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-teal-500 to-cyan-600">
                      <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Manage your barn
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Schedule your lessons, plan appointments, and organize show calendars with ease.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 sm:pr-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <StaticImage
                    src="../images/show-calendar.png"
                    quality={100}
                    className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 "
                    imgClassName="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    format={["auto", "webp", "avif"]}
                    alt="horse show calendar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterLight />
    </div>
  </div>
)

export default IndexPage
